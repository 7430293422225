import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Content from '../components/Content';
import { PortableText } from '@portabletext/react';
import urlBuilder from '@sanity/image-url';

const ProjectIntro = styled.div`
  display: block;
  max-width: 660px;
  margin: 0 auto var(--pad);

  h2 {
    margin-bottom: calc(var(--pad) / 2);
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.325;

    @media screen and (min-width: 750px) {
      font-size: 2rem;
    }
  }
`;

const ProjectContent = styled.div`
  display: block;
  margin: 1em auto var(--pad);

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    max-width: 660px;
    margin: 0 auto var(--pad);
  }

  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5 {
    padding-top: 1rem;
  }

  img {
    display: block;
    width: 100%;
    max-width: 880px;
    height: auto;
    margin: 0 auto var(--pad);
  }
`;

const Video = styled.div`
  display: block;
  width: 100%;
  max-width: 660px;
  margin: 0 auto var(--pad);

  div {
    position: relative;
    display: block;
    width: 100%;
    height: 320px;
    padding-bottom: 56.25%; /*16:9*/
    height: 0;
    overflow: hidden;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const sanityConfig = {
  projectId: 'pryqyx89',
  dataset: 'production',
};

const components = {
  types: {
    image: ({ value }) => {
      return value.asset ? (
        <img
          src={urlBuilder(sanityConfig)
            .image(value.asset)
            .width(1200)
            .quality(85)
            .url()}
          alt={value.asset.alt || ' '}
          loading="lazy"
        />
      ) : null;
    },
    youtube: ({ value }) => {
      const { youtube_video_id } = value;
      return youtube_video_id ? (
        <Video>
          <div>
            <iframe
              width="720"
              height="576"
              src={`https://www.youtube.com/embed/${youtube_video_id}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Video>
      ) : null;
    },
  },
};

const ProjectPage = ({ pageContext }) => {
  const { title, agency, client, featured, _rawRichContent } = pageContext;

  return (
    <Layout page="Work">
      <Content>
        <ProjectIntro>
          <h2>{title}</h2>
          <p>
            <b>Client: </b>
            {client}
          </p>
          <p>
            <b>Agency: </b>
            {agency}
          </p>
          {featured.length > 0 && (
            <p>
              <b>Featured in:</b>{' '}
              {featured.map(({ feature_title, feature_link }, index) => {
                return (
                  <span key={feature_link}>
                    <a href={feature_link}>{feature_title}</a>
                    {featured.length - 1 !== index && ', '}
                  </span>
                );
              })}
            </p>
          )}
        </ProjectIntro>

        <ProjectContent>
          <PortableText value={_rawRichContent} components={components} />
        </ProjectContent>
      </Content>
    </Layout>
  );
};

export default ProjectPage;
